import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'


const BlockReviews = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
      <>

      <div className='mt-10 grid grid-cols-2  Flex items-center'>
        <div className='w-[30%] justify-start '>
            <img src='https://firebasestorage.googleapis.com/v0/b/resource-36ef7.appspot.com/o/yelp.png?alt=media&token=a3e20e35-b2fc-44cb-8c81-eae6060034dd&_gl=1*1xpxuzv*_ga*MTYxMTEwNzY0NC4xNjk1OTE1NDcy*_ga_CW55HF8NVT*MTY5NzA0MjUwMi42LjEuMTY5NzA0MjYyOC4xNi4wLjA.' alt=''/>
        </div>
        <div className='justify-end items-end w-[50%]'>
            <a href='https://www.yelp.com/biz/mobile-foam-auto-detail-pittsburg' target='_blank' rel='noopener noreferrer' className='block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800' >
                Write Reviews
            </a>
        </div>
      </div>
      <div className='grid grid-cols-1 gap-8 mt-8 xl:mt-12 lg:grid-cols-2 xl:grid-cols-3 m-12'>
        {
            rpdata?.works?.map((item, index) => {
                return(
                    <section className="">
                    <div key={index} className='p-8 border rounded-lg dark:border-gray-700'>
                      <p className="leading-loose text-black dark:text-black">
                        {item.description}
                      </p>
          
                      <div className="flex items-center mt-8 -mx-2">
                        <img
                          className="object-cover mx-2 rounded-full w-14 shrink-0 h-14 ring-4 ring-gray-300 dark:ring-gray-700"
                          src={item.gallery[0]}
                          alt=""
                        />         
                        <div className="mx-2">
                          <p className="font-semibold text-gray-800 dark:text-black">
                            {item.name}
                          </p>

                          <span class="text-lg  dark:text-yellow-400 text-yellow-400 ">&#9733;&#9733;&#9733;&#9733;&#9733; </span>
                        </div>
                      </div>
                    </div>
                  </section>
                )
            })
        }
    </div>
      </>
    );
}

export default BlockReviews